import React from 'react'
import { useNavigate } from 'react-router-dom';

import './Banner.css';


const Banner = () => {

  const navigate = useNavigate();
  const goToContact = () => {
    navigate('/ContactUs');
  }
  const product = useNavigate();
  const goToProduct = () => {
    product('/Product');
  }

  return (
    <>

      <div className='banner'>
        <div className='banner-main'>
          <div className='intro-banner'>
            <h2 >Bengal Remedies</h2>
            <h1>Clear Strategy Consistent Performance</h1>
            <p>Outstanding quality systems, skilled and knowledgeable team<br /> and the best in class equipment at our 7 manufacturing facilities<br /> ensure that our patients get nothing but the best in quality.</p>
          </div>


          <div className='button-banner'>
            <button className='banner-button' onClick={() => goToProduct()}>
              Our Products
            </button>
            <button className='banner-button-two' onClick={() => goToContact()}>
              Contact Us
            </button>
          </div>

        </div>





      </div>





    </>
  )
}

export default Banner;











