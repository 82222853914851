import React, { useState } from 'react'
import './Navbar.css';
import { TbGridDots } from 'react-icons/tb';
import Logo from '../../Images/logonew.gif'
import { NavLink } from 'react-router-dom';



const Navbar = () => {

    const [active, setActive] = useState('navBar');

    const showNav = () => {
        setActive(prevState => prevState === 'navBar activeNavbar' ? 'navBar' : 'navBar activeNavbar');
    }
    


    return (
        <section className='navBarSection'>
            <header className='header flex'>

                <div className='logoDiv'>
                    <NavLink to='/' className='logo'> <img src={Logo} alt='logo' className='brand' style={{ width: '150px', height: '100px' }}></img>
                    </NavLink>
                </div>

                <div className={active}>
                    <ul className='navLists flex'>

                        <li className='navItems'>
                            <NavLink to='/' className='navLink' onClick={showNav} >Home</NavLink>
                        </li>

                        <li className='navItems'>
                            <NavLink to='/about' className='navLink' onClick={showNav} >About
                            </NavLink>
                        </li>

                        <li className='navItems'>
                            <NavLink to='/Product' className='navLink' onClick={showNav} >Products</NavLink>
                        </li>

                        <li className='navItems'>
                            <NavLink to='/Corporate Social' className='navLink' onClick={showNav} >Corporate Social</NavLink>
                        </li>

                        <li className='navItems'>
                            <NavLink to='/Pharma Industry' className='navLink' onClick={showNav} >Pharma Industry</NavLink>
                        </li>

                        <li className='navItems'>
                            <NavLink to='/gallery' className='navLink' onClick={showNav}>Gallery</NavLink>
                        </li>

                        <li className='navItems'>
                            <NavLink to='/Social Media' className='navLink' onClick={showNav}>Social Media</NavLink>
                        </li>

                        <button className='btn'>
                            <NavLink to='/ContactUs' onClick={showNav}>Contact Us</NavLink>
                        </button>

                    </ul>

                    {/* <div onClick={removeNav} className='closeNavbar'>
                        <AiFillCloseCircle className='iconGrid' />
                    </div> */}
                </div>

                <div onClick={showNav} className='toggelNavbar'>
                    <TbGridDots className='icon' />
                </div>



            </header>
        </section>
    )
}

export default Navbar
