import React from 'react'
import { useNavigate } from 'react-router-dom';
import './Promotions.css'
import OneFour from '../../Images/Rectangle14.svg'
import OneFive from '../../Images/Rectangle15.svg'
import OneSix from '../../Images/Rectangle16.svg'
import OneSeven from '../../Images/Rectangle17.svg'
import { FaMobileAlt } from "react-icons/fa";
import SocialWork from './SocialWork'






const Promotions = () => {

    const navigate = useNavigate();
    const goToContact = () => {
        navigate('/ContactUs');
    }

    return (
        <>

            <section className='section-two'>

                <div className='main-box'>

                    <div className='right'>
                        <div className='right-one'>
                            <img className='one-four' src={OneFour} alt=''></img>
                            <img className='one-five' src={OneFive} alt=''></img>
                        </div>

                        <div className='right-two'>
                            <img className='one-six' src={OneSix} alt=''></img>
                            <img className='one-seven' src={OneSeven} alt=''></img>
                        </div>
                    </div>



                    <div className='left'>
                        <h1 className='head'>Our Achievemments </h1>
                        <h2 className='head-two'>Differentiated & Smart Product</h2>
                        <p>We aim to serve not only server our customer in rural area but to help new comers who wants to start up their whole sale ,
                            backed by our capital of honesty, hard work and sincerity to generate further employment opportunities
                            which is crucial now-a-days throughout the country. That's how we are evolving our customer's number and able to
                            build their setup in business which made us proud .</p>

                        <p>The other part of the business is distribution and deliver at time to customer's doorstep for customers satisfaction and trust.
                            In this Corona situation also we are delivering and distributing by putting our best effort and resource. We are
                            having our own transport also to deliver on time . </p>
                        <div className='call-us'>

                            <button className='button-four' onClick={() => goToContact()}>
                                Contact Us
                            </button>

                            <FaMobileAlt className='icc icon'></FaMobileAlt>
                            <p>  
                             +91 8981089350 <br />
                              +91 9433019750
                              </p>
                        </div>
                    </div>

                </div>

            </section>
<SocialWork></SocialWork>

        </>
    )
}

export default Promotions
