import React, { useState, useEffect } from 'react';

import './Gallery.css';
import GalleryAPI from '../apiServices/GalleryApi';

const Gallery = () => {
  const [tourImages, setTourImages] = useState([]);
  const [productImages, setProductImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [showTourImages, setShowTourImages] = useState(true);
  const [showProductImages, setShowProductImages] = useState(false);
  const [showVideos, setShowVideos] = useState(false);
  const [loadingTourImages, setLoadingTourImages] = useState(false);
  const [loadingProductImages, setLoadingProductImages] = useState(false);
  const [loadingVideos, setLoadingVideos] = useState(false);

  useEffect(() => {
    fetchTourImage();
    fetchProductImage();
    fetchVideos();
  }, []);

  const fetchTourImage = async () => {
    try {
      setLoadingTourImages(true);
      const images = await GalleryAPI.getTourImage();
      setTourImages(images);
    } catch (error) {
      console.error('Error fetching tour images:', error);
    } finally {
      setLoadingTourImages(false);
    }
  };

  const fetchVideos = async () => {
    try {
      setLoadingVideos(true);
      const videos = await GalleryAPI.getVideos();
      setVideos(videos);
    } catch (error) {
      console.error('Error fetching videos:', error);
    } finally {
      setLoadingVideos(false);
    }
  };

  const fetchProductImage = async () => {
    try {
      setLoadingProductImages(true);
      const images = await GalleryAPI.getProductImage();
      setProductImages(images);
    } catch (error) {
      console.error('Error fetching product images:', error);
    } finally {
      setLoadingProductImages(false);
    }
  };

  const handleTourButtonClick = () => {
    setShowTourImages(true);
    setShowVideos(false);
    setShowProductImages(false);
  };

  const handleProductButtonClick = () => {
    setShowTourImages(false);
    setShowVideos(false);
    setShowProductImages(true);
  };

  const handleVideoButtonClick = () => {
    setShowVideos(true);
    setShowTourImages(false);
    setShowProductImages(false);
  };

  return (
    <div
      className='gallery-both'
      style={{
        height: tourImages.length > 5 || productImages.length > 5 ? 'auto' : '150vh',
      }}
    >
      <div className='gallery-filter'>
        <button
          onClick={handleTourButtonClick}
          className={showTourImages ? 'active' : ''}
        >
          Tour and funtime
        </button>
        <button
          onClick={handleProductButtonClick}
          className={showProductImages ? 'active' : ''}
        >
          Product at a glance
        </button>
        <button
          onClick={handleVideoButtonClick}
          className={showVideos ? 'active' : ''}
        >
          Videos
        </button>
      </div>

      <div className='images'>
        {showTourImages ? (
          loadingTourImages ? (
            <p className='p'>Loading tour images...</p>
          ) : tourImages && tourImages.length > 0 ? (
            tourImages.map((image) => (
              <div className='upload-images' key={image.id}>
                <div className='image'>
                  <img src={image.url} alt={image.urlType} />
                </div>
              </div>
            ))
          ) : (
            <p className='p'>No Tour Images Found</p>
          )
        ) : showVideos ? (
          loadingVideos ? (
            <p className='p'>Loading videos ...</p>
          ) : videos && videos.length > 0 ? (
            videos.map((video, index) => (
              <div className="videos" key={video.id}>
                <iframe
                  title={`YouTube Video ${index + 1}`}
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${video.url}`}
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
            ))
          ) : (
            <p className='p'>No Videos Found</p>
          )
        ) : (
          loadingProductImages ? (
            <p className='p'>Loading product images...</p>
          ) : productImages && productImages.length > 0 ? (
            productImages.map((image) => (
              <div className='upload-images' key={image.id}>
                <div className='image'>
                  <img src={image.url} alt={image.title} />
                </div>
              </div>
            ))
          ) : (
            <p className='p'>No Product Images Found</p>
          )
        )}
      </div>
    </div>
  );
};

export default Gallery;
