import React from 'react'
import './Footer.css';
import { FaTwitter, FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa';
const Footer = () => {
    return (
        <>

            <div className='footer-end'>

             <div className='footer-menu'> 
               
                <ul className="social-icons ">
                    <li>
                        <a href="/">
                            <FaTwitter />
                        </a>
                    </li>
                    <li>
                        <a href="/">
                            <FaFacebook />
                        </a>
                    </li>
                    <li>
                        <a href="/">
                            <FaInstagram />
                        </a>
                    </li>
                    <li>
                        <a href="/">
                            <FaYoutube />
                        </a>
                    </li>
                </ul>
                <p> © 2023 copyright  | Privacy Policy  | All rights reserved</p>
            </div>

            </div> 

        </>
    )
}

export default Footer;
