import React from 'react'
import FaceBook from '../../Images/image3.svg'
import { FaWhatsapp } from 'react-icons/fa';
import './SocialMedia.css'



const SocialMedia = () => {
    return (
        <>
            <div className='social-media'>
                <div className='facebook'>
                    <img className='img-face' src={FaceBook} alt='facebook-im'></img>
                </div>

                <div className='whatsapp'>

                <FaWhatsapp className='icon-social'/>
                <div className='label-wa'>WhatssApp Integration</div>

                </div>


            </div>

        </>
    )
}

export default SocialMedia;
