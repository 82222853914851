import axios from 'axios';

const GalleryAPI = {
  getTourImage: async () => {
    try {
      const response = await axios.get('http://13.127.16.211:9090/api/website-data/images?type=tour');
      return response.data.payload;
    } catch (error) {
      console.error(error);
      return [];
    }
  },

  getVideos: async () => {
    try {
      const response = await axios.get('http://13.127.16.211:9090/api/website-data/videos?type=tour');
      return response.data.payload;
    } catch (error) {
      console.error(error);
      return [];
    }
  },

  getProductImage: async () => {
    try {
      const response = await axios.get('http://13.127.16.211:9090/api/website-data/images?type=product');
      return response.data.payload;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

export default GalleryAPI;
