import axios from 'axios';

const SideBarApi ={

    fetchData: async () => {
        try {
          const [typeResponse, categoryResponse] = await Promise.all([
            axios.get('http://13.127.16.211:9090/api/product/type', {
              headers: { 'Content-Type': 'application/json' },
            }),
            axios.get('http://13.127.16.211:9090/api/product/category', {
              headers: { 'Content-Type': 'application/json' },
            }),
          ]);
    
          const types = typeResponse.data.payload || [];
          const category = categoryResponse.data.payload || [];
    
          return { types, category };
        } catch (error) {
          console.error(error);
          return { types: [], category: [] };
        }
      }

}
export default SideBarApi;