import React from 'react'
import './Corporate.css'
import TwoTwo from '../../Images/Rectangle22.svg'

const Corporate = () => {
    return (
        <>

            <div className='corporate'>

                <div className='right-corporate'>


                    <h1 className='head'>Bengal Remedies  -----</h1>

                    <h2 className='head-two'>Corporate Social Responsibility</h2>

                    <p className='p-one'>We aim to serve not only server our customer in rural area but to help new comers who wants to start up their whole sale ,
                        backed by our capital of honesty, hard work and sincerity to generate further employment opportunities
                        which is crucial now-a-days throughout the country. That's how we are evolving our customer's number and able to
                        build their setup in business which made us proud .</p>

                    <p className='p-two'>The other part of the business is distribution and deliver at time to customer's doorstep for customers satisfaction and trust.
                        In this Corona situation also we are delivering and distributing by putting our best effort and resource. We are
                        having our own transport also to deliver on time . </p>


                </div>


                <div className='left-corporate'>


                    <div className='right-one'>
                        {/* <img className='twoOne' src={TwoOne} alt=''></img>  */}
                        <img className='twoTwo' src={TwoTwo} alt=''></img>
                    </div>


                </div>







            </div>



        </>
    )
}

export default Corporate
