import React, { useState } from 'react';
import { GoLocation } from 'react-icons/go';
import { FaEnvelope } from 'react-icons/fa';
import { MdPhone } from 'react-icons/md';
import { FaWhatsapp } from 'react-icons/fa';
import './Contact.css';

const Contact = () => {
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', { name, email, phone, message });
  };

  return (
    <>
      <div className='contact-form'>
        <form className='form-input' onSubmit={handleSubmit}>
          <h1>Contact Us</h1>
          <h2>Reach Us For Any Enquiry</h2>
          <div className='form-row'>
            <label>
              <input
                type='text'
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder='First Name'
              />
            </label>
            <label>
              <input
                type='text'
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder='Last Name'
              />
            </label>
          </div>

          <div className='form-roww'>
            <label>
              <input
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder=' E-mail'
              />
            </label>
            <label>
              <input
                type='tel'
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder='Phone Number'
              />
            </label>
          </div>

          <label className='message-box'>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder='Drop Your Message Here'
            />
          </label>

          <div>
            <button className='button-form' type='submit'>
              Submit
            </button>
          </div>
        </form>

        <div className='card'>
          <h1>Get In Touch</h1>
          <h2>Address</h2>
          <div className='add'>
            <GoLocation className='icon-l' />
            <p>
              Address: 82, Netaji Subhash Chandra Bose Road, Uttarpara, WestBengal - 712258
            </p>
          </div>

          <div className='email'>
            <FaEnvelope className='icon-e' />
            <br />
            <p>Mail: ben.remedies@gmail.com</p>
          </div>
          <div className='phone-number'>
            <MdPhone className='icon-p' />
            <span>( Somnath Chatterjee MD)</span>
            <p>+91 8981089350 </p>
            <p>+91 9433019750</p>
          </div>

          <div className='contact-info'>
            <a
              href='https://wa.me/918981089350'
              target='_blank'
              rel='noopener noreferrer'
            
            >
              <FaWhatsapp className='icon-w' />
            </a>
            <p>+91 8981089350 </p>
            <p>+91 9433019750</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
