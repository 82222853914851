export async function getAllProducts(data) {
    
    try {
        const response = await fetch(`http://13.127.16.211:9090/api/products/search`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
            }
        )
        
        const responseObj = await response.json();
        const p = responseObj.payload.data;
        const total = responseObj.payload.total;
        return {p, total};
    } 
    catch (error) 
    {
      console.error('Error fetching products:', error);
      return [];
    }
}
