
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import About from './Components/about/About';
import Product from './Components/Product/Product';
import Corporate from './Components/others/Corporate';
import Contact from './Components/contact/Contact';
// import Banner from './Components/Banner';
import Home from './Components/common/Home';
import Promotions from './Components/others/Promotions';
import SocialMedia from './Components/others/SocialMedia';
import Footer from './Components/common/Footer';
import Error from './Components/common/Error';

import Navbar from './Components/common/Navbar';
import Gallery from './Components/gallery/Gallery';

function App() {
  return (
    <Router>

      <Navbar />
      
      <Routes>
        <Route>
          <Route path='/' element={<Home />} />
          <Route path='/About' element={<About />} />
          <Route path='/Product' element={<Product/ >} />
          <Route path='/Corporate Social' element={<Corporate />} />
          <Route path='/ContactUs' element={<Contact/>} />
          <Route path='/Pharma Industry' element={<Promotions />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/Social Media' element={<SocialMedia />} />
          <Route path='/*' element={<Error />} />

        </Route>
      </Routes>

      <Footer />
   
    </Router>
  );
}

export default App;
