import React from 'react'

import Banner from './Banner'


const Home = () => {
  return (
    <>
      
      
      <Banner></Banner>
      
    
    </>
  )
}

export default Home
