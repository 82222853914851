import React, { useState, useEffect, useRef } from 'react';
import Ima from '../../Images/no-image.png';
import SideBar from './SideBar';
import { getAllProducts } from '../apiServices/ProductApi';
import './Product.css';
import Pagination from '@mui/material/Pagination';

function Product() {
  const PAGE_SIZE = 32;
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [composition, setComposition] = useState('');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const compositionRef = useRef(null);
  const DEFAULT_PAGINATION = {
    limit: PAGE_SIZE,
    offset: 0,
  };
  const [data, setData] = useState({
    paginationRequest: DEFAULT_PAGINATION,
    searchFilter: {
      keyword: null,
      type: null,
      isWebsite: true,
    },
    sort: {
      fieldName: 'id',
      sort: 'ASC',
    },
  });

  const handleChange = (event, value) => {
    setPage(value);
    setData({
      ...data,
      paginationRequest: {
        ...data.paginationRequest,
        offset: value - 1,
      },
    });
  };

  useEffect(() => {
    async function fetchData() {
      const { p, total } = await getAllProducts(data);
      setProducts(p);
      setTotal(total);
    }
    fetchData();
  }, [data]);

  const handleMouseOver = (product) => {
    setComposition(product.composition);
  };

  const handleMouseOut = () => {
    setComposition('');
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  const handleCloseClick = () => {
    setSelectedProduct(null);
  };

  const handleCategoryClick = (category) => {
    setData({
      ...data,
      paginationRequest: DEFAULT_PAGINATION,
      searchFilter: {
        ...data.searchFilter,
        category: category,
      },
    });
  };

  const handleDivisionClick = (division) => {
    setData({
      ...data,
      paginationRequest: DEFAULT_PAGINATION,
      searchFilter: {
        ...data.searchFilter,
        division: division,
      },
    });
  };

  const handleTypeClick = (type) => {
    setData({
      ...data,
      paginationRequest: DEFAULT_PAGINATION,
      searchFilter: {
        ...data.searchFilter,
        type: type,
      },
    });
  };

  const handleSearchProduct = (text) => {
    setData({
      ...data,
      paginationRequest: DEFAULT_PAGINATION,
      searchFilter: {
        ...data.searchFilter,
        keyword: text,
      },
    });
  };

  return (
    <>
      <div className='product-section'>
        <div className='left-side'>
          <SideBar
            onSearchProduct={handleSearchProduct}
            onCategoryClick={handleCategoryClick}
            onDivisionClick={handleDivisionClick}
            onTypeClick={handleTypeClick}
          />
        </div>
        <div className='right-item'>
          <ul className='product-list'>
            {products && products.length > 0 ? (
              products.map((product) => (
                <div className='product' key={product.id}>
                  <div className='prod-image'>
                    {product.image ? (
                      <img
                        src={product.image}
                        alt={product.name}
                        height='150'
                        onClick={() => handleProductClick(product)}
                      />
                    ) : (
                      <img
                        src={Ima}
                        alt={product.name}
                        height='150'
                        onClick={() => handleProductClick(product)}
                      />
                    )}
                  </div>
                  <div>
                    <h4>{product.name}</h4>
                    <div
                      onMouseOver={() => handleMouseOver(product)}
                      onMouseOut={handleMouseOut}
                    >
                      <span
                        ref={compositionRef}
                        dangerouslySetInnerHTML={{
                          __html:
                            composition === product.composition
                              ? composition
                              : product.composition
                              ? product.composition.substring(0, 30) + '...'
                              : '',
                        }}
                      ></span>
                    </div>
                  </div>
                  <div className='type-product'>{product.type}</div>
                </div>
              ))
            ) : (
              <div className='npf-container'>
                <p className='npf'>No product found.</p>
              </div>
            )}
          </ul>
          <div className='pagination-container'>
            <Pagination
              color='secondary'
              variant='outlined'
              count={Math.ceil(total / PAGE_SIZE)}
              page={page}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      {selectedProduct && (
        <div className='popup'>
          <div className='popup-content'>
            <span className='close' onClick={handleCloseClick}>
              &times;
            </span>
            <img
              src={selectedProduct.image}
              alt={selectedProduct.name}
              className='popup-image'
            />
          </div>
        </div>
      )}
    </>
  );
}

export default Product;
