import React from 'react'
import './SocialWork.css'
import { FaMobileAlt } from "react-icons/fa";
import ThreeZero from '../../Images/Rectangle30.svg'
import ThreeOne from '../../Images/Rectangle31.svg'
import { useNavigate } from 'react-router-dom';

const SocialWork = () => {

  const navigate = useNavigate();
  const goToContact = () => {
    navigate('/ContactUs');
  }
  
  return (
    <>

      <div className='social'>


        <div className='right-social'>
          <h1>Specialty Pharmaceutical Company</h1>
          <h2>Social Work</h2>
          <p>Lorem Ipsum has been the industry's standard dummy text ever since the
            when an unknown printer took a galley of type and scrambled it to make a
            type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.

            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using look like readable English.</p>

          <div className='call-us-three' onClick={() => goToContact()}>
            <button className='button-three'>
              Contact Us
            </button>
            <FaMobileAlt className='ic icon'></FaMobileAlt>
            <p>+91 8981089350 <br/>
              +91 9433019750</p>
          </div>
        </div>



        <div className='left-social'>
          <div>
            <img className='three-z' src={ThreeZero} alt='rec30'></img>
            <img className='three-o' src={ThreeOne} alt='rec31'></img>
          </div>

        </div>



      </div>








    </>
  )
}

export default SocialWork
