import React from 'react'
import './Error.css'

const Error = () => {
  return (
    <>
    
    <div className="error-container">
        
        <h1 className='page-not'>404 Page Not Found</h1>
         <p className='mess'>Sorry, the page you are looking for cannot be found.</p>
        <a className='go-back' href="/">Go back to homepage</a>
         
    </div>
    
    
    </>
  )
}

export default Error
