import React, { useState, useEffect } from 'react';
import SideBarApi from '../apiServices/SideBarApi';
// import { FaTrash } from 'react-icons/fa';
import { TiDelete } from 'react-icons/ti';
import './SideBar.css';

const SideBar = ({ onCategoryClick, onSearchProduct, onTypeClick, onDivisionClick }) => {
  const [wordEntered, setWordEntered] = useState('');
  const [types, setTypes] = useState([]);
  const [division, setDivision] = useState([]);
  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [selectedDivision, setSelectedDivision] = useState('');

  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    setSelectedCategory(selectedCategory);
    onCategoryClick(selectedCategory);
  };

  const handleTypeChange = (event) => {
    const selectedType = event.target.value;
    setSelectedType(selectedType);
    onTypeClick(selectedType);
  };

  const handleDivisionChange = (event) => {
    const selectedDivision = event.target.value;
    setSelectedDivision(selectedDivision);
    onDivisionClick(selectedDivision);
  };

  useEffect(() => {
    const fetchData = async () => {
      const { types, category } = await SideBarApi.fetchData();
      setTypes(types);
      setCategory(category);
      setDivision(['BengalRemedies', 'BengalPharma', 'DwarkaRemedies', 'DwarkaPharma']);
      setLoading(false);
    };

    fetchData();
  }, []);

  const handleWordEntered = (event) => {
    setWordEntered(event.target.value);
    onSearchProduct(event.target.value);
  };

  const handleClear = (field) => {
    switch (field) {
      case 'category':
        setSelectedCategory('');
        onCategoryClick('');
        break;
      case 'type':
        setSelectedType('');
        onTypeClick('');
        break;
      case 'division':
        setSelectedDivision('');
        onDivisionClick('');
        break;
      default:
        break;
    }
  };

  return (
    <div className='sidebar'>
      <div className='searchInputs'>
        <input
          className='search-input'
          type='text'
          placeholder='Search'
          value={wordEntered}
          onChange={handleWordEntered}
        />
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <div className='product-search'>
            <label className='product-search-label' htmlFor='searchDivisionSelect'>
              Select Division:
            </label>
            <div className='select-field'>
              <select
                id='searchDivisionSelect'
                className='product-search-select'
                value={selectedDivision}
                onChange={handleDivisionChange}
              >
                <option value=''></option>
                {division.map((curElem) => (
                  <option key={curElem} value={curElem}>
                    {curElem}
                  </option>
                ))}
              </select>
              {selectedDivision && (
                <TiDelete className='clear-icon' onClick={() => handleClear('division')} />
              )}
            </div>
          </div>

          <div className='product-search'>
            <label className='product-search-label' htmlFor='searchTypeSelect'>
              Select Type:
            </label>
            <div className='select-field'>
              <select
                id='searchTypeSelect'
                className='product-search-select'
                value={selectedType}
                onChange={handleTypeChange}
              >
                <option value=''></option>
                {types.map((curElem) => (
                  <option key={curElem.id} value={curElem.name}>
                    {curElem.name}
                  </option>
                ))}
              </select>
              {selectedType && (
                <TiDelete className='clear-icon' onClick={() => handleClear('type')} />
              )}
            </div>
          </div>

          <div className='product-search'>
            <label className='product-search-label' htmlFor='searchCategorySelect'>
              Select Category:
            </label>
            <div className='select-field'>
              <select
                id='searchCategorySelect'
                className='product-search-select'
                value={selectedCategory}
                onChange={handleCategoryChange}
              >
                <option value=''></option>
                {category.map((curElem) => (
                  <option key={curElem} value={curElem}>
                    {curElem}
                  </option>
                ))}
              </select>
              {selectedCategory && (
                <TiDelete className='clear-icon' onClick={() => handleClear('category')} />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SideBar;
