import React from 'react'
import Recfoursix from '../../Images/Rectangle46.svg';
import Receleven from '../../Images/Rectangle11.svg';
import { FaMobileAlt } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

import './About.css';

const About = () => {
  const navigate = useNavigate();
  const goToContact = () => {
    navigate('/ContactUs');
  }

  return (
    <>

      <section className='about'>
        <div className='box'>

          <div className='first'>
            <h1>20+ Years of Experience</h1>
            <h2>About Company</h2><br />
            <p>July 1, 2001, we started our journey with the name and style of “BENGAL REMEDIES”, a Marketing
              and Wholesale Trading House of pharmaceutical products, on 1st July 2001 the particular
              day that is regarded as Doctors’ day, the birthday and the day of peaceful demise of
              Dr. Bidhan Chandra Roy (1882 - 1962).</p> <br></br>
            <p>The journey of thousand and thousand miles began with one step at 2001 which never stops.
              It is a great pleasure on our part that we celebrate our each and every foundation day in
              several parts of india including Kolkata.</p><br></br>
            <p>We are just not a business organization – we are a family, a "Pariwar", we don’t work just for the sake of a Company.
              We work for ourselves for the growth of our "Pariwar". "Emotion gives the motion of a life” and emotional involvement
              enhances the degree of productivity, and it is needless to say that a man can never achieve to the tune of 100%
              without the combination of emotion crowned with Professionalism. We are neither the employees nor the employer or
              distributor; we are all the member of an “Emotionally integrated family”</p><br></br>
            <div className='call-us-one'>
              <button className='button-three' onClick={() => goToContact()}>
                Contact Us
              </button>
              <FaMobileAlt className='ic icon'></FaMobileAlt>
              <p>+91 8981089350 <br />
                +91 9433019750</p>
            </div>



          </div>

          <div className='secound'>
            <img className='eleven' src={Recfoursix} alt=''></img>

            <img className='foursix' src={Receleven} alt='' ></img>
          </div>
          <div className='blank'></div>
        </div>
      </section>


    </>
  )
}

export default About
